import React from 'react';
import { graphql } from 'gatsby';

import { Layout } from '../layout/Layout';
import Seo from '../components/common/Seo';
import { Information, OurHistory, OurValues, Sponsors } from '../components/modules/about-us';

const NosotrosPage = ({ data }) => {
  const { hero_title, hero_text, hero_image, story, values } = data.directus.about_us;

  return (
    <Layout pageId="about-us">
      <Information heading={hero_title} text={hero_text} image={hero_image} />
      <OurHistory list={story} />
      <OurValues list={values} />
      <Sponsors />
    </Layout>
  );
};

export const Head = () => <Seo title="Nosotros | Hisense" />;

export const query = graphql`
  query AboutUsPageQuery {
    directus {
      about_us {
        hero_title
        hero_text
        hero_image {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        story(sort: "sort") {
          id
          years
          title
          text
          image {
            id
            title
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        values(sort: "sort") {
          id
          title
          text
          image {
            id
            title
            imageFile {
              publicURL
            }
          }
          images {
            directus_files_id {
              id
              title
              imageFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default NosotrosPage;
