import React from "react";
import Slider from 'react-slick';

const SliderSection = ({ title, className, sliderSettings, children }) => {
  return (
    <section id="slider-section" className={className}>
      <div className="container flex flex-col items-center justify-center">
        <h2 className="mb-6 text-center text-[32px] font-bold leading-10 md:mb-8 md:text-[40px]">{title}</h2>
        <div className="w-full">
          <Slider {...sliderSettings}>{children}</Slider>
        </div>
      </div>
    </section>
  );
};

export default SliderSection;
