import React from 'react';

const OurValues = ({ list }) => {
  return (
    <section id="our-values">
      <div className="container">
        <div className="row">
          <div className="mx-auto md:col-10">
            <h2 className="text-2 title">Conoce nuestros valores</h2>
            <div className="row gap-y-10 md:gap-y-8">
              {list.map((item, index) => {
                return (
                  <div key={item.id} className={`${index === 2 ? 'md:col-12' : 'md:col-6'}`}>
                    <div
                      className={`h-full flex flex-col rounded-2xl bg-white p-8 md:p-14 gap-6 items-center md:items-start ${
                        index === 2 ? 'md:flex-row' : 'md:flex-col'
                      }`}
                    >
                      <img
                        src={item.image.imageFile.publicURL}
                        alt={item.image.title}
                        className="image"
                        height={112}
                        width={112}
                      />
                      <div className="text-center md:text-left">
                        <h5 className="mb-2 text-2xl font-bold md:mb-4">{item.title}</h5>
                        <div className="[&>p]:mb-5 last:[&>p]:mb-0" dangerouslySetInnerHTML={{ __html: item.text }} />

                        {item.images.length > 0 && (
                          <div className="mt-8 flex flex-col flex-wrap justify-between gap-6 md:flex-row">
                            {item.images.map(({ directus_files_id: image }) => {
                              return (
                                <div
                                  key={image.id}
                                  className="flex flex-1 items-center gap-4 md:max-w-[160px] md:flex-col md:gap-6"
                                >
                                  <picture className="h-12 w-12 md:h-14 md:w-14">
                                    <img src={image.imageFile.publicURL} alt={image.title} className="mx-auto h-full" />
                                  </picture>
                                  <h6 className="text-center text-xl font-bold">{image.title}</h6>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurValues;
