import React, { useRef } from 'react';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

import Slider from 'react-slick';

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  adaptiveHeight: true,
};

const OurHistory = ({ list }) => {
  const slickRef = useRef();

  const handleSlickNext = () => {
    slickRef.current.slickNext();
  };

  return (
    <section id="our-history">
      <div className="container">
        <div className="row">
          <div className="history-content relative mx-auto pt-6 lg:col-10 md:pt-16">
            <h2 className="text-2 mb-8 text-center text-white lg:mb-16">Nuestra historia</h2>
            <Slider ref={slickRef} {...settings}>
              {list.map((item) => {
                return (
                  <div className="row !flex">
                    <div className="flex items-center pl-14 lg:col-6 lg:pl-4">
                      <div>
                        <p className="relative text-[40px] font-medium leading-[48px] text-[#00AAA6] xl:text-[80px] xl:leading-[100px]">
                          {item.years}
                        </p>
                        <h3 className="text-3 mb-4 mt-2 w-full text-white lg:my-6">{item.title}</h3>
                        <div
                          className="w-full [&>p]:mb-5 [&>p]:text-white"
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                        <button className="mb-5 hidden lg:block" onClick={handleSlickNext}>
                          <StaticImage
                            className="image"
                            src="../../../assets/images/icons/circle-arrow--bottom.svg"
                            alt="Circle arrow bottom"
                            placeholder="blurred"
                          />
                        </button>
                      </div>
                    </div>

                    <div className="flex items-center pb-14 pl-14 lg:col-5">
                      <GatsbyImage
                        image={getImage(item.image.imageFile)}
                        alt={item.image.title}
                        className="w-full rounded-2xl"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurHistory;
