import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Information = ({ heading, text, image }) => {
  getImage(image.imageFile);
  return (
    <section id="information" className="mt-16 pb-10 pt-12 lg:mt-20 lg:py-0">
      <div className="container">
        <div className="row">
          <div className="flex flex-col justify-end px-0 lg:col-6 lg:px-4">
            <h1 className="text-2 mb-8 px-4 text-center lg:hidden">{heading}</h1>

            <GatsbyImage image={getImage(image.imageFile)} alt={image.title} className="mb-10 w-full lg:mb-0" />
          </div>

          <div className="flex items-center lg:col-6">
            <div className="w-full lg:ml-8 lg:max-w-[600px]">
              <h1 className="text-2 mb-8 hidden lg:block">{heading}</h1>
              <div className="[&>p]:mb-5" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Information;
