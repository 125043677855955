import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SliderSection from '../../common/SliderSection';

const Sponsors = () => {
  const data = useStaticQuery(graphql`
    query SponsoredEventsQuery {
      directus {
        about_us {
          sponsored_events {
            directus_files_id {
              id
              title
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 298)
                }
              }
            }
          }
        }
      }
    }
  `);

  const { sponsored_events } = data.directus.about_us;

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <SliderSection
      title="Sponsor de los mejores eventos deportivos del mundo"
      className="pb-16 pt-12 md:py-24"
      sliderSettings={settings}
    >
      {sponsored_events.map((event, index) => {
        const { title, imageFile } = event.directus_files_id;
        return (
          <div key={index}>
            <div className="flex h-[120px] items-center justify-center px-4">
              <GatsbyImage image={getImage(imageFile)} alt={title} />
            </div>
          </div>
        );
      })}
    </SliderSection>
  );
};

export default Sponsors;
